<template>
  <template v-for="daily_games in picks" :key="daily_games.id">
    <section class="game-section">
      <div class="game-date">
        {{ daily_games.date }}
      </div>

      <table class="game-table table table-striped">
        <thead>
          <tr>
            <td>Region</td>
            <td>Team</td>
            <td>Time</td>
            <td>Pick</td>
            <td>Ft</td>
            <td>Result</td>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in daily_games.data" :key="item.id">
            <tr>
              <td>{{ item.region }}</td>
              <td>{{ item.team }}</td>
              <td>{{ item.time }}</td>
              <td>{{ item.pick }}</td>
              <td>{{ item.ft }}</td>
              <td>
                <svg
                  v-if="item.outcome === true"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 19L2 12L4.5 9.5L9 14L19.5 3.5L22 6L9 19Z"
                    fill="green"
                  />
                </svg>
                <svg
                  v-else-if="item.outcome === false"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 18L18 6M6 6L18 18"
                    stroke="red"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <svg
                  v-else
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="#d2000e96"
                    stroke-width="2"
                  />
                  <path
                    d="M12 6V12L15 15"
                    stroke="#d2000e96"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </section>
  </template>
</template>




<script>
export default {
  name: "PicksTable",
  props: {
    picks: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Add custom styles here if needed */
</style>