<template>


<nav class="container navbar navbar-expand-lg navbar-light bg-light">
  

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarToggler">
    <div class="col-sm-2">
    <router-link to="/" class="navbar-brand" >
    <img class="site-logo" src="@/assets/logo.png"/>
  </router-link>
    </div>
  <div class="col-sm-6">
    <ul class="navbar-nav">
      <li class="nav-link"><router-link to="/" exact-active-class="active">Home</router-link></li>
        <li class="nav-link"><router-link to="/basketball" exact-active-class="active">Basketball</router-link></li>
    </ul>
  </div>
    <div class="right-nav col-sm-4">
    
    </div>
  </div>
</nav>

   

  </template>
  
  <script>
  export default {
    name: 'NavBar',
  }
  </script>