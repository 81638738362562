<template>
    
   
      <table class="game-table table table-striped">
        <thead>
    <tr>
      <td>Team</td>
      <td>Time</td>
      
    </tr>
  </thead>
    <tbody>
      <template v-for="item in upcoming" :key="item.id">
        <tr>
          <td>{{ item.team }}</td>
          <td>{{ formatTime(item.match_time) }}</td> 
        </tr>
      </template>
    </tbody>
  </table>
 
 




</template>


 
  
  <script>
  export default {
    name: 'UpcomingMatches',
    data() {
      return {
      
    }
  },
  props: {
    upcoming: {
      type: Array,
      required: true
    }
  },
  methods: {
    formatTime(time) {
      /*
      let [hours, minutes, seconds] = time.split(':');
      hours = parseInt(hours, 10);
      const amPm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12;
      hours = hours < 10 ? '0' + hours : hours.toString();
      minutes = minutes.length < 2 ? '0' + minutes : minutes;
      seconds = seconds.length < 2 ? '0' + seconds : seconds;
       return `${hours}:${minutes} ${amPm}`;
      */
      return  time.slice(0, -3);
    }
  }
  }
  </script>
  
  <style scoped>
  /* Add custom styles here if needed */
  </style>
  