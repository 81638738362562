import Home from './components/Home.vue'
import Basketball from './components/Basketball.vue'
//import Tennis from './components/Tennis.vue'

const routes = [
  { 
  path: '/', 
  component: Home, 
  meta: { title: 'Home - Accurate sport statistics website' }

},
  { 
  path: '/basketball',
  component: Basketball,
  meta: { title: 'Basketball' }
},
  //{ path: '/tennis', component: Tennis },
]

export default routes