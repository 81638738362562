<template>
  <div id="app">
<NavBar />
    <div class="container site-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
//import HelloWorld from './components/HelloWorld.vue'
import NavBar from '@/components/NavBar.vue'
export default {
  name: 'App',
  components: {
    NavBar
  }
}
</script>

<style>
html, body {
background: #dadada !important;  
}

.site-wrapper {
  background: #fff;
    border-radius: 12px;
    margin-top: 30px;
    padding-top: 40px;
}
.navbar-brand
{
  padding-left: 20px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
.game-date
{
  text-align: left;
}
.game-table thead tr td{
  background-color: #d2000e;
  color:#f9f7ec;
  font-weight: bold;
}
.game-table tbody tr td{
  font-size: 14px;
  font-weight: 500;
}
.game-section
{
  background: #efefef;
}
nav
{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

}
.right-nav{
  border-bottom-right-radius: 12px;
}
.right-nav {
            position: relative;
            background-color: #d2000e;
            color: white;
            min-height: 75px;
        
            overflow: hidden;
        }
        .right-nav::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            border-top: 200px solid #f8f9fa;
            border-right: 50px solid transparent;
        }
        .right-nav .content {
            position: relative;
            z-index: 1;
        }

        .nav-link a{
          color:#8a8a8a;
          text-decoration: none;
          font-weight: bold;
        }
        .nav-link .active{
          color:#171616;
        }
        .game-date, .section-title
        {
          color: #171616;
    font-weight: bold;
    padding: 10px;
        }

        .page-item .page-link
        {
          color:#000 !important;
          border-color:transparent;
          margin: 5px;
        }

        .page-item.active .page-link
        {
          color:#fff !important;
          font-weight: bold;
          background-color: #d2000e;
          border-color:#d2000e;
        }
</style>
