<template>
  <div class="row">
    <div class="col-sm-8">
      <PicksTable v-if="!isLoading" :picks="picksData" />
      <Preloader v-else />
    </div>
    <div class="col-sm-4">
      <section class="game-section">
        <div class="section-title">Upcoming matches</div>
        <UpcomingMatches v-if="!isLoading" :upcoming="upcomingData" />
        <nav
          aria-label="Page navigation example"
          v-if="pagination.total > pagination.per_page"
        >
          <ul class="pagination">
            <li
              v-for="page in pagination.last_page"
              :key="page"
              class="page-item"
              :class="{ active: page === pagination.current_page }"
            >
              <a class="page-link" @click.prevent="changePage(page)" href="#">{{
                page
              }}</a>
            </li>
          </ul>
        </nav>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PicksTable from "@/components/PicksTable.vue";
import UpcomingMatches from "@/components/UpcomingMatches.vue";
import Preloader from "@/components/Preloader.vue";
export default {
  name: "Basketball",
  components: {
    PicksTable,
    UpcomingMatches,
    Preloader,
  },
  data() {
    return {
      picksData: [],
      isLoading: true,
      apiUrl: process.env.VUE_APP_API_URL,
      upcomingData: [],
      pagination: {
        total: 0,
        per_page: 0,
        current_page: 0,
        last_page: null,
      },
    };
  },

  created() {
    this.fetchPicksData();
  },

  methods: {
    async fetchPicksData() {
      try {
        const response = await axios.post(`${this.apiUrl}/picks`, {
          type: "basketball",
        });
        this.picksData = response.data.picks;
        this.upcomingData = response.data.upcoming;
        /*
        this.upcomingData = response.data.upcoming.data;
        this.pagination = {
          total: response.data.upcoming.total,
          per_page: response.data.upcoming.per_page,
          current_page: response.data.upcoming.current_page,
          last_page: response.data.upcoming.last_page,
        };
        */
      } catch (error) {
        console.error("Error fetching picks data:", error);
      } finally {
        this.isLoading = false;
      }
    },
    changePage(page) {
      if (page !== this.pagination.current_page) {
        this.fetchPicksData(page);
      }
    },
  },
};
</script>