import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import routes from './routes'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import { BootstrapVueNext } from 'bootstrap-vue-next'

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Welcome';
  next();
});
const app = createApp(App)
app.use(router)
app.use(BootstrapVueNext)
app.mount('#app')